/* jshint esnext: true */
export {default as Nav} from './modules/Nav';
export {default as Dropdown} from './modules/Dropdown';
export {default as SliderHome} from './modules/SliderHome';
export {default as SliderPage} from './modules/SliderPage';
export {default as SliderProject} from './modules/SliderProject';
export {default as LightboxVideo} from './modules/LightboxVideo';
export {default as HeaderPage} from './modules/HeaderPage';
export {default as Carousel} from './modules/Carousel';
export {default as CarouselTimer} from './modules/CarouselTimer';
export {default as CarouselNews} from './modules/CarouselNews';
export {default as SmoothScrolling} from './modules/SmoothScrolling';
export {default as NavNews} from './modules/NavNews';
export {default as News} from './modules/News';
export {default as LocationSwitcher} from './modules/LocationSwitcher';
export {default as Filters} from './modules/Filters';
export {default as ContactForm} from './modules/ContactForm';
export {default as PageTransitionsManager} from './modules/PageTransitionsManager';
export {default as Search} from './modules/Search';
export {default as SimilarSwitcher} from './modules/SimilarSwitcher';
